/**
 * MainRowBodyContentLG 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 01/01/2020
 */

import React, { Component } from 'react';
import imageNotFound from '../img/imageNotFound.png';
import SantuaryLink from './santuaryLink';
import he from 'he';


export default class MainRowBodyContentLG extends Component {

    constructor(props) {
        super(props);
        //images
        let img1 = this.props.img1 != null ? this.props.img1 : imageNotFound;
        let img2 = this.props.img2 != null ? this.props.img2 : imageNotFound;
        let img3 = this.props.img3 != null ? this.props.img3 : imageNotFound;

        //image objects
        let img1obj = this.props.img1obj;
        let img2obj = this.props.img2obj;
        let img3obj = this.props.img3obj;

        this.defaultLink = new SantuaryLink('', 'Not defined');

        //links
        let link1 = this.props.link1 != null ? this.props.link1 : this.defaultLink;
        let link2 = this.props.link2 != null ? this.props.link2 : this.defaultLink;
        let link3 = this.props.link3 != null ? this.props.link3 : this.defaultLink;

        this.state = {
            imageBox1: img1,
            imageBox2: img2,
            imageBox3: img3,
            linkBox1: link1,
            linkBox2: link2,
            linkBox3: link3,
            imageObject1: img1obj,
            imageObject2: img2obj,
            imageObject3: img3obj
        }
    }


    render() {

        return (<div className="row">
            <div className="col-md-4" align="center">

                <div className="row">
                    <div className="col-md-12 d-xl-none d-sm-none d-none d-md-block d-lg-block"><img src={this.state.imageBox1} className="rounded" alt="virgen de coromoto" /></div>
                </div>
                <div className="row">
                    <div className="col-md-12 d-xl-none d-sm-none d-none d-md-block d-lg-block"><a className='text-link' href={this.state.imageObject1 != null ? this.state.imageObject1 : this.state.linkBox1.getURL()} >{he.decode(this.state.linkBox1.getLinkedText())}</a></div>
                </div>

            </div>
            <div className="col-md-4" align="center">

                <div className="row">
                    <div className="col-md-12 d-xl-none d-sm-none d-none d-md-block d-lg-block"><img src={this.state.imageBox2} className="rounded" alt="virgen de coromoto" /></div>
                </div>
                <div className="row">
                    <div className="col-md-12 d-xl-none d-sm-none d-none d-md-block d-lg-block">
                        <a className='text-link' href={this.state.imageObject2 != null ? this.state.imageObject2 : this.state.linkBox2.getURL()} target="_blank" rel="noopener noreferrer" >{he.decode(this.state.linkBox2.getLinkedText())}</a>
                    </div>
                </div>

            </div>
            <div className="col-md-4" align="center">

                <div className="row">
                    <div className="col-md-12 d-xl-none d-sm-none d-none d-md-block d-lg-block"><img src={this.state.imageBox3} className="rounded" alt="virgen de coromoto" /></div>
                </div>
                <div className="row">
                    <div className="col-md-12 d-xl-none d-sm-none d-none d-md-block d-lg-block">
                        <a className='text-link' href={this.state.imageObject3 != null ? this.state.imageObject3 : this.state.linkBox3.getURL()} target="_blank" rel="noopener noreferrer" >{he.decode(this.state.linkBox3.getLinkedText())}</a>
                    </div>
                </div>

            </div>
        </div>

        );
    }

}