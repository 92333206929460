/**
 * MainRowBodyContentSM 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 01/01/2020
 */

import React, { Component } from 'react';
import imageNotFound from '../img/imageNotFound.png';
import SantuaryLink from './santuaryLink';
import he from 'he';


export default class MainRowBodyContentSM extends Component {

    constructor(props) {
        super(props);
        //images
        let img1 = this.props.img1 != null ? this.props.img1 : imageNotFound;
        let img2 = this.props.img2 != null ? this.props.img2 : imageNotFound;

        //image objects
        let img1obj = this.props.img1obj;
        let img2obj = this.props.img2obj;

        this.defaultLink = new SantuaryLink('', 'Not defined');

        //links
        let link1 = this.props.link1 != null ? this.props.link1 : this.defaultLink;
        let link2 = this.props.link2 != null ? this.props.link2 : this.defaultLink;

        this.state = {
            imageBox1: img1,
            imageBox2: img2,
            linkBox1: link1,
            linkBox2: link2,
            imageObject1: img1obj,
            imageObject2: img2obj
        }
    }


    render() {

        return (
            <div className="row">
                <div className="col-sm-6" align="center">

                    <div className="row">
                        <div className="col-12 d-xl-none d-lg-none d-md-none d-none d-sm-block"><img src={this.state.imageBox1} className="rounded" alt="virgen de coromoto" /></div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-xl-none d-lg-none d-md-none d-none d-sm-block">
                            <a className='text-link' href={this.state.imageObject1 != null ? this.state.imageObject1 : this.state.linkBox1.getURL()} target="_blank" rel="noopener noreferrer" >{he.decode(this.state.linkBox1.getLinkedText())}</a>
                        </div>
                    </div>

                </div>
                <div className="col-sm-6" align="center">

                    <div className="row">
                        <div className="col-12 d-xl-none d-lg-none d-md-none d-none d-sm-block"><img src={this.state.imageBox2} className="rounded" alt="virgen de coromoto" /></div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-xl-none d-lg-none d-md-none d-none d-sm-block">
                            <a className='text-link' href={this.state.imageObject2 != null ? this.state.imageObject2 : this.state.linkBox2.getURL()} target="_blank" rel="noopener noreferrer" >{he.decode(this.state.linkBox2.getLinkedText())}</a>
                        </div>
                    </div>

                </div>
            </div>

        );
    }

}