/**
 * Index 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 29/12/2019
 */

import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from './header';
import MainSlider from './mainSliders';
import MainTitle from './mainTitle';
import MainRowBodyContentXL from './mainRowBodyContentXL';
import MainRowBodyContentLG from './mainRowBodyContentLG';
import MainRowBodyContentSM from './mainRowBodyContentSM';
import MainRowBodyContentEXSM from './mainRowBodyContentEXSM';
import NewsResumeComponent from './NewsResumeComponent';
import Footer from './footer';
import SantuaryLink from './santuaryLink';
import sm_wf_iconVideos from '../img/gallery-holy-relic/sm-wide-format/videos.jpg';
import sm_wf_historyAparition from '../img/gallery-holy-relic/sm-wide-format/historyAparition.jpg';
import sm_wf_findingsHolyRelic from '../img/gallery-holy-relic/sm-wide-format/hallazgosSantaReliquia.jpg';
import sm_wf_findingsHolyRelic2 from '../img/gallery-holy-relic/sm-wide-format/findingsHolyRelic.jpg';
import sm_wf_prayers from '../img/gallery-holy-relic/sm-wide-format/prayers.jpg';
import sm_wf_holyMass from '../img/gallery-holy-relic/sm-wide-format/wf-holyMass.jpg';
import sm_wf_campoCoronacion from '../img/gallery-holy-relic/sm-wide-format/campoCoronacion.jpg';
import sm_wf_holyMass2024 from '../img/gallery-holy-relic/sm-wide-format/holyMass2024.jpg';
import sm_wf_tourVirtual from '../img/gallery-holy-relic/sm-wide-format/tour_basilica_virgen_coromoto.jpg';
import sm_wf_beatos from '../img/gallery-holy-relic/sm-wide-format/beatos.jpg';
import sm_wf_donaciones from '../img/gallery-holy-relic/sm-wide-format/santuario-donaciones.jpg';
import xl_aparitionHistory from '../img/home/319_252/historia_aparicion_movil.jpg';
import xl_conociendoSantaReliquia from '../img/home/319_252/conociendoSantaReliquia.jpg';
import xl_historiaSantuario from '../img/home/319_252/historiaSantuario.jpg';
import xl_descargasMultimedia from '../img/home/319_252/descargasMultimedia.jpg';
import xl_galeriaMultimedia from '../img/home/319_252/galeriaMultimedia.jpg';
import xl_hallazgosSantaReqliquia from '../img/home/319_252/hallazgosSantaReliquia.jpg';
import xl_tourVirtual from '../img/home/319_252/tourVirtual319.png';
import xl_videoSantaReliquia from '../img/home/319_252/icono-videos.jpg';
import xl_beatos from '../img/home/319_252/beatos_venezolanos-319.jpg';
import lg_beatos from '../img/home/231_202/beatos_venezolanos.jpg';
import lg_aparitionHistory from '../img/home/231_202/aparitionHistory.jpg';
import lg_conociendoSantaReliquia from '../img/home/231_202/holyRelicOurLadyOfCoromoto.png';
import lg_historiaSantuario from '../img/home/231_202/historySantuary.jpg';
import lg_descargasMultimedia from '../img/home/231_202/multimedia.jpg';
import lg_hallazgosSantaReliquia from '../img/home/231_202/FindingsRelicOurLadyCoromoto.jpg';
import lg_tourVirtual from '../img/home/231_202/TourVirtual.png';
import lg_videos from '../img/home/231_202/videos.jpg';
import lg_prayers from '../img/home/231_202/prayers.jpg';
import lg_holyRelic from '../img/home/231_202/holyRelic.jpg';
import lg_location from '../img/home/231_202/ubicacion.jpg';
import lg_massSchedule from '../img/home/231_202/massSchedule.jpg';


export default class Index extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        if (sessionStorage.getItem('language') !== null) {
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };
        }
        else {
            this.state = { lang: language, eventChangeLanguage: onLanguageChange };
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);
    }

    componentDidMount() {
        if (sessionStorage.getItem('language') !== null) {
            if (this.state.eventChangeLanguage !== null && typeof this.state.eventChangeLanguage !== "undefined") {
                this.setState({ lang: sessionStorage.getItem('language') });
                sessionStorage.setItem('language', sessionStorage.getItem('language'));
                this.state.eventChangeLanguage(sessionStorage.getItem('language'));
            }
        }
    }

    refreshLanguage(value) {
        if (value !== null && value !== '') {
            this.setState({ lang: value });
        }
    }

    render() {
        let historyApparitionLb;
        let historyApparitionURL;
        let knowingHolyRelicLb, knowingHolyRelicURL;
        let sanctuaryHistoryLb, sanctuaryHistoryURL;
        let findingsHolyRelicLb, findingsHolyRelicURL;
        let downloadMultimediaLb, downloadMultimediaURL;
        let videosMultimediaLb, videosMultimediaURL;
        let locationLb, locationURL;
        let massScheduleLb, massScheduleURL;
        let tourVirtualSanctuaryLb, tourVirtualSanctuaryURL;
        let videosLb, videosURL;
        let prayersLb, prayersURL;
        let imagesLb, imagesURL;
        let blessedLb, blessedURL;
        let donationsLb, donationsURL;
        let pastoralLetterLb, pastoralLetterURL;
        let holyRosaryLb, holyRosaryURL;
        let helmetComp;
        let newsBasilicaLb, newsBasilicaURL;

        if (this.state.lang === 'es') {
            historyApparitionLb = "Historia Aparici&oacute;n";
            historyApparitionURL = "historia-virgen-coromoto.html";
            knowingHolyRelicLb = "Conociendo la Santa Reliquia";
            knowingHolyRelicURL = "santa-reliquia-virgen-coromoto.html";
            sanctuaryHistoryLb = "Historia Santuario";
            sanctuaryHistoryURL = "historia-santuario-nacional-virgen-coromoto.html";
            findingsHolyRelicLb = "Hallazgos Santa Reliquia Marzo 2009";
            findingsHolyRelicURL = "hallazgos-santa-reliquia-virgen-coromoto.html";
            downloadMultimediaLb = "Descargas Multimedia";
            downloadMultimediaURL = "descargas-multimedia-santuario-nacional-virgen-coromoto.html";
            locationLb = "Ubicaci&oacute;n";
            locationURL = "ubicacion-santuario-virgen-coromoto.html";
            massScheduleLb = "Horarios de Misas";
            massScheduleURL = "horario-misa-santuario-nacional-virgen-coromoto.html";
            tourVirtualSanctuaryLb = "Tour Virtual 360°";
            tourVirtualSanctuaryURL = "tour-virtual-santuario-nacional-virgen-coromoto.html";
            videosLb = "Videos";
            videosURL = "videos-santuario-nacional-virgen-coromoto.html";
            prayersLb = "Oraciones";
            prayersURL = "oraciones-virgen-de-coromoto.html";
            imagesLb = "Galer&iacute;a Im&aacute;genes Virgen de Coromoto";
            imagesURL = "imagenes-hallazgos-reliquia-virgen-coromoto.html";
            blessedLb = "Beatos de Venezuela";
            blessedURL = "beatos-venezuela.html";
            donationsLb = "Donaciones";
            donationsURL = "donaciones-santuario-nacional-virgen-coromoto.html";
            pastoralLetterLb = "Carta Pastoral Hallazgos Santa Reliquia";
            pastoralLetterURL = "carta-pastoral-santa-reliquia-virgen-coromoto.html";
            holyRosaryLb = "Oremos el Santo Rosario";
            holyRosaryURL = "santo-rosario.html";
            newsBasilicaLb = "Noticias Santuario";
            newsBasilicaURL = "noticias-santuario-virgen-coromoto.html";
            videosMultimediaLb = "Videos de la Santísima Virgen María de Coromoto";
            videosMultimediaURL = "videos-santuario-nacional-virgen-coromoto.html";

            //seo 
            helmetComp = <Helmet>
                <title>Bas&iacute;lica Menor Santuario Nacional Nuestra Se&ntilde;ora de Coromoto</title>
                <meta name="description" content="Bas&iacute;lica Menor Santuario Nacional Nuestra Se&ntilde;ora de Coromoto" />
                <meta name="keywords" content="Coromoto, Virgen, Maria, Santuario, Basilica" />
            </Helmet>;
        }
        else {
            historyApparitionLb = "Apparition History";
            historyApparitionURL = "history-our-lady-of-coromoto.html";
            knowingHolyRelicLb = "Knowing the Holy Relic";
            knowingHolyRelicURL = "knowing-holy-relic-our-lady-of-coromoto.html";
            sanctuaryHistoryLb = "Sanctuary History";
            sanctuaryHistoryURL = "history-sanctuary-our-lady-of-coromoto.html";
            findingsHolyRelicLb = "Findings Holy Relic March 2009";
            findingsHolyRelicURL = "findings-holy-relic-our-lady-of-coromoto.html";
            downloadMultimediaLb = "Download Multimedia";
            downloadMultimediaURL = "download-multimedia-our-lady-of-coromoto.html";
            locationLb = "Location";
            locationURL = "location-our-lady-of-coromoto.html";
            massScheduleLb = "Mass Schedule";
            massScheduleURL = "mass-schedule-minor-basilica-our-lady-coromoto.html";
            tourVirtualSanctuaryLb = "Tour Virtual 360°";
            tourVirtualSanctuaryURL = "tour-virtual-santuario-nacional-virgen-coromoto.html";
            videosLb = "Videos";
            videosURL = "videos-our-lady-of-coromoto.html";
            prayersLb = "Prayers";
            prayersURL = "prayers-to-our-lady-of-coromoto.html";
            imagesLb = "Images Gallery";
            imagesURL = "images-findings-our-lady-of-coromoto.html";
            blessedLb = "Blessed";
            blessedURL = "blessed.html";
            donationsLb = "Donations";
            donationsURL = "donations-sanctuary-our-lady-of-coromoto.html";
            pastoralLetterLb = "Pastoral Letter Findings Holy Relic March 2009";
            pastoralLetterURL = "pastoral-letter-holy-relic-coromoto.html";
            holyRosaryLb = "Pray the Holy Rosary";
            holyRosaryURL = "holy-rosary.html";
            newsBasilicaLb = "Sanctuary News";
            newsBasilicaURL = "news-our-lady-coromoto.html";
            videosMultimediaLb = "Videos Virgin Mary of Coromoto";
            videosMultimediaURL = "videos-santuario-nacional-virgen-coromoto.html";

            //seo 
            helmetComp = <Helmet>
                <title>Minor Basilica of Our Lady Of Coromoto Official Website</title>
                <meta name="description" content="Minor Basilica of Our Lady Of Coromoto Official Website" />
                <meta name="keywords" content="Coromoto, Mary, Lady, Basilica, Sanctuary" />
            </Helmet>;

        }


        return (<div>
            {helmetComp}
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <MainSlider />
            <div className="container">
                <div className="row"><div className="col-12">&nbsp;</div></div>
                <MainTitle />
                <MainRowBodyContentXL
                    img1={xl_aparitionHistory}
                    img2={xl_conociendoSantaReliquia}
                    img3={xl_historiaSantuario}
                    link1={new SantuaryLink(historyApparitionURL, historyApparitionLb)}
                    link2={new SantuaryLink(knowingHolyRelicURL, knowingHolyRelicLb)}
                    link3={new SantuaryLink(sanctuaryHistoryURL, sanctuaryHistoryLb)} />

                <div className="row">
                    <div className="col-xl-12 col-lg-none d-md-none d-sm-none d-none d-xl-block">&nbsp;</div>
                </div>

                <MainRowBodyContentXL
                    img1={xl_descargasMultimedia}
                    img2={xl_galeriaMultimedia}
                    img3={xl_hallazgosSantaReqliquia}
                    link1={new SantuaryLink(downloadMultimediaURL, downloadMultimediaLb)}
                    link2={new SantuaryLink(imagesURL, imagesLb)}
                    link3={new SantuaryLink(findingsHolyRelicURL, findingsHolyRelicLb)}
                />

                <div className="row">
                    <div className="col-xl-12 col-lg-none d-md-none d-sm-none d-none d-xl-block">&nbsp;</div>
                </div>

                <MainRowBodyContentXL
                    img1={xl_tourVirtual}
                    img2={xl_videoSantaReliquia}
                    img3={xl_beatos}
                    link1={new SantuaryLink(tourVirtualSanctuaryURL, tourVirtualSanctuaryLb)}
                    link2={new SantuaryLink(videosURL, videosLb)}
                    link3={new SantuaryLink(blessedURL, blessedLb)}
                />


                <MainRowBodyContentLG
                    img1={lg_aparitionHistory}
                    img2={lg_historiaSantuario}
                    img3={lg_hallazgosSantaReliquia}
                    link1={new SantuaryLink(historyApparitionURL, historyApparitionLb)}
                    link2={new SantuaryLink(sanctuaryHistoryURL, sanctuaryHistoryLb)}
                    link3={new SantuaryLink(findingsHolyRelicURL, findingsHolyRelicLb)}
                />

                <MainRowBodyContentLG
                    img1={lg_conociendoSantaReliquia}
                    img2={lg_holyRelic}
                    img3={lg_prayers}
                    link1={new SantuaryLink(pastoralLetterURL, pastoralLetterLb)}
                    link2={new SantuaryLink(knowingHolyRelicURL, knowingHolyRelicLb)}
                    link3={new SantuaryLink(prayersURL, prayersLb)}
                />

                <MainRowBodyContentLG
                    img1={lg_descargasMultimedia}
                    img2={lg_location}
                    img3={lg_massSchedule}
                    link1={new SantuaryLink(downloadMultimediaURL, downloadMultimediaLb)}
                    link2={new SantuaryLink(locationURL, locationLb)}
                    link3={new SantuaryLink(massScheduleURL, massScheduleLb)}
                />

                <MainRowBodyContentLG
                    img1={lg_tourVirtual}
                    img2={lg_videos}
                    img3={lg_beatos}
                    link1={new SantuaryLink(tourVirtualSanctuaryURL, tourVirtualSanctuaryLb)}
                    link2={new SantuaryLink(videosURL, videosLb)}
                    link3={new SantuaryLink(blessedURL, blessedLb)}
                />

                <div className="row">
                    <div className="col-md-12 d-xl-none d-sm-none d-none d-md-block d-lg-block">&nbsp;</div>
                </div>


                <MainRowBodyContentSM
                    img1={lg_aparitionHistory}
                    img2={lg_hallazgosSantaReliquia}
                    link1={new SantuaryLink(historyApparitionURL, historyApparitionLb)}
                    link2={new SantuaryLink(findingsHolyRelicURL, findingsHolyRelicLb)}
                />

                <MainRowBodyContentSM
                    img1={lg_historiaSantuario}
                    img2={lg_conociendoSantaReliquia}
                    link1={new SantuaryLink(sanctuaryHistoryURL, sanctuaryHistoryLb)}
                    link2={new SantuaryLink(pastoralLetterURL, pastoralLetterLb)}
                />

                <MainRowBodyContentSM
                    img1={lg_hallazgosSantaReliquia}
                    img2={lg_prayers}
                    link1={new SantuaryLink(knowingHolyRelicURL, knowingHolyRelicLb)}
                    link2={new SantuaryLink(prayersURL, prayersLb)}
                />


                <MainRowBodyContentSM
                    img1={lg_tourVirtual}
                    img2={lg_videos}
                    link1={new SantuaryLink(tourVirtualSanctuaryURL, tourVirtualSanctuaryLb)}
                    link2={new SantuaryLink(videosURL, videosLb)}
                />

                <div className="row">
                    <div className="col-12 d-xl-none d-lg-none d-md-none d-none d-sm-block">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={sm_wf_historyAparition}
                    link1={new SantuaryLink(historyApparitionURL, historyApparitionLb)}
                />

                <div className="row">
                    <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={sm_wf_findingsHolyRelic}
                    link1={new SantuaryLink(findingsHolyRelicURL, findingsHolyRelicLb)}
                />

                <div className="row">
                    <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={sm_wf_findingsHolyRelic2}
                    link1={new SantuaryLink(pastoralLetterURL, pastoralLetterLb)}
                />

                <div className="row">
                    <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={sm_wf_prayers}
                    link1={new SantuaryLink(prayersURL, prayersLb)}
                />

                <div className="row">
                    <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block">&nbsp;</div>
                </div>


                <MainRowBodyContentEXSM
                    img1={sm_wf_holyMass}
                    link1={new SantuaryLink(massScheduleURL, massScheduleLb)}
                />

                <div className="row">
                    <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={sm_wf_iconVideos}
                    link1={new SantuaryLink(videosMultimediaURL, videosMultimediaLb)}
                />

                <div className="row">
                    <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={sm_wf_campoCoronacion}
                    link1={new SantuaryLink(downloadMultimediaURL, downloadMultimediaLb)}
                />

                <div className="row">
                    <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={sm_wf_holyMass2024}
                    link1={new SantuaryLink(imagesURL, imagesLb)}
                />

                <div className="row">
                    <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block">&nbsp;</div>
                </div>


                <MainRowBodyContentEXSM
                    img1={sm_wf_tourVirtual}
                    link1={new SantuaryLink(tourVirtualSanctuaryURL, tourVirtualSanctuaryLb)}
                />

                <div className="row">
                    <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={sm_wf_beatos}
                    link1={new SantuaryLink(blessedURL, blessedLb)}
                />

                <div className="row">
                    <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={sm_wf_donaciones}
                    link1={new SantuaryLink(donationsURL, donationsLb)}
                />


                <NewsResumeComponent />

                <div className="row">
                    <div className="col-12 d-none d-md-block d-xl-block d-lg-block">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12 d-none d-md-block d-xl-block d-lg-block">&nbsp;</div>
                </div>

            </div>
            <Footer />
        </div>);
    }

}