


export default class Video {

    constructor(key, idVideoYouTube, urlYouTube, imageYouTube, title, description) {
        this.key = key;
        this.idVideoYouTube = idVideoYouTube;
        this.urlYouTube = urlYouTube;
        this.imageYouTube = imageYouTube;
        this.title = title;
        this.description = description;
    }

    getKey() {
        return this.key;
    }

    getIdVideoYouTube() {
        return this.idVideoYouTube;
    }

    getUrlYouTube() {
        return this.urlYouTube;
    }

    getImageYouTube() {
        return this.imageYouTube;
    }

    getTitle() {
        return this.title;
    }

    getDescription() {
        return this.description;
    }

}