import Video from '../components/Video';



export default class CoreServices {

    getVideosList() {
        let videosList = [];

        let video1 = new Video(1, 'MX7R5SOzr78', 'https://www.youtube.com/embed/MX7R5SOzr78?autoplay=1&rel=0', 'historia-aparicion.png', 'Relato Historia de la Aparición de la Virgen Santísima María de Coromoto', 'Historia de la Aparición de la Santísima Virgen María de Coromoto Patrona de Venezuela y explicación de hallagos encontrados en el tratamiento de conservación realizado en Marzo 2009');
        let video2 = new Video(2, '8fRyWqrOidA', 'https://www.youtube.com/embed/8fRyWqrOidA?autoplay=1&rel=0','resplandor-bella-señora.png', 'EL RESPLANDOR DE LA BELLA SEÑORA', 'EL RESPLANDOR DE LA BELLA SEÑORA video de la explicación de la aparición de la Santísima Virgen María de Coromoto Patrona de Venezuela de la Fundación María Camino a Jesús');
        let video3 = new Video(3, 'XFhjAb9nY9o', 'https://www.youtube.com/embed/XFhjAb9nY9o?autoplay=1&rel=0','conferencia-pablo-jimenez.png', 'Conferencia sr Pablo González en el Santuario Nacional', 'Conferencia sr Pablo González en el Santuario Nacional sobre los hallazgos encontrados a la Santa Reliquia de la Santísima Virgen María de Coromoto Patrona de Venezuela realizada el 03 de Febrero del 2024');
        let video4 = new Video(4, 'vmlwC6PFqzQ', 'https://www.youtube.com/embed/vmlwC6PFqzQ?autoplay=1&rel=0','conferencia-nancy-jimenez.png', 'Conferencia sra Nancy Jiménez en el Santuario Nacional', 'Conferencia sra Nancy Jiménez en el Santuario Nacional sobre los hallazgos encontrados a la Santa Reliquia de la Santísima Virgen María de Coromoto Patrona de Venezuela realizada el 03 de Febrero del 2024');
        let video5 = new Video(5, 'w9PS__-EiQA', 'https://www.youtube.com/embed/w9PS__-EiQA?autoplay=1&rel=0','testimonio-prebistero-allender.png', 'Testimonio del Presbítero Allender Hernandez Rector de la Basílica Menor de Nuestra Señora de Coromoto', 'Testimonio del Presbítero Allender Hernandez Rector de la Basílica Menor de Nuestra Señora de Coromoto realizada el 03 de Febrero del 2024');
        let video6 = new Video(6, 'aYDscqFGv_4', 'https://www.youtube.com/embed/aYDscqFGv_4?autoplay=1&rel=0','hallazgos-santa-reliquia-virgen-coromoto.png', 'Hallazgos Santa Reliquia Virgen de Coromoto', 'Hallazgos encontrados en el tratamiento de conservación a la Santa Reliquia de la Santísima Virgen María de Coromoto Patrona de Venezuela');
        let video7 = new Video(7, 'vebhVq9oozE', 'https://www.youtube.com/embed/vebhVq9oozE?autoplay=1&rel=0','conferencia-maria-garcia-fleury.png', 'Conferencia sra María García de Fleury en el Santuario', 'Conferencia sra María García de Fleury en el Santuario sobre los hallazgos encontrados a la Santa Reliquia de la Santísima Virgen María de Coromoto Patrona de Venezuela realizada el 03 de Febrero del 2024');
        let video8 = new Video(8, 'OVQbfCyzmFA', 'https://www.youtube.com/embed/OVQbfCyzmFA?autoplay=1&rel=0','santuario-nacional-virgen-coromoto.png', 'Santuario Nacional Nuestra Señora de Coromoto', 'Video de recorrido en la Basílica Menor Santuario Nacional de Nuestra Señora de Coromoto Patrona de Venezuela');
        let video9 = new Video(9, 'UMHhv1e1tR8', 'https://www.youtube.com/embed/UMHhv1e1tR8?autoplay=1&rel=0','pablo-gonzalez-santa-reliquia-virgen-coromoto.png', 'El revelador descubrimiento de la Santa Reliquia de la Virgen de Coromoto', 'Entrevista al Sr Pablo González sobre los hallazgos encontrados a la Santa Reliquia de la Santísima Virgen María de Coromoto Patrona de Venezuela');

        videosList.push(video1);
        videosList.push(video2);
        videosList.push(video3);
        videosList.push(video4);
        videosList.push(video5);
        videosList.push(video6);
        videosList.push(video7);
        videosList.push(video8);
        videosList.push(video9);

        return videosList;
    }
}