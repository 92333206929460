/**
 * Header 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 01/04/2020
 */

import React, { Component } from 'react';
import header1154 from '../img/Header_1154.png';
import header992 from '../img/Header_992.png';
import header1154_us from '../img/Header_1154_us.png';
import header992_us from '../img/Header_992_us.png';
import Menu from './Menu';
import MenuExtraSmall from './MenuExtraSmall';

export default class Header extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        let sendDataProps = this.props.sendData != null ? this.props.sendData : null;
        this.state = { lang: language, eventChangeLanguage: onLanguageChange, sendD: sendDataProps };
        this.updateLanguage = this.updateLanguage.bind(this);
    }

    updateLanguage(language) {
        this.setState({ lang: language });
    }

    render() {

        let header1154Img;
        let header992Img;



        if (this.state.lang === 'es') {
            header1154Img = <img src={header1154} alt='Virgen de Coromoto' />;
            header992Img = <img src={header992} alt='Virgen de Coromoto' />;


        }
        else {
            if (this.state.lang === 'en') {
                header1154Img = <img src={header1154_us} alt='Virgen de Coromoto' />;
                header992Img = <img src={header992_us} alt='Virgen de Coromoto' />;


            }
        }


        return (

            <div className="sticky-top" style={{ 'backgroundColor': '#f3d593', 'borderColor': '#c3a03b' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 d-lg-none d-md-none d-sm-none d-none d-xl-block" align="center" style={{ "paddingRight": "0px", "paddingLeft": "0px" }}>{header1154Img}</div>
                        <div className="col-lg-12 d-xl-none d-md-none d-sm-none d-none d-lg-block" align="center" style={{ "paddingRight": "0px", "paddingLeft": "0px" }}>{header992Img}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 d-none d-md-none d-sm-none d-lg-block d-xl-block">
                            <Menu
                                language={this.state.lang}
                                onLanguageChange={this.state.eventChangeLanguage}
                                sendData={this.state.sendD}
                                updateL={this.updateLanguage}
                            />
                        </div>
                        <div className="col-12 d-lg-none d-xl-none d-sm-block d-md-block d-block">
                            <MenuExtraSmall
                                language={this.state.lang}
                                onLanguageChange={this.state.eventChangeLanguage}
                                sendData={this.state.sendD}
                                updateL={this.updateLanguage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}