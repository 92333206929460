/**
 * Index 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 29/12/2019
 */

import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import sliderSantuario from '../img/sanctuary/sliderSantuario.jpg';
import history1 from '../img/sanctuary/santuarioVirgenCoromoto-small.jpg';
import history2 from '../img/sanctuary/SanctuarySmall1.jpg';
import sliderLg from '../img/sanctuary/sliderSantuario-2.jpg';
import sliderMd from '../img/sanctuary/slidersSacraments-2.jpg';

export default class SanctuaryHistorySlider extends Component {

    render() {
        return(
            <div className="row">
                <div className="col-xl-12 d-sm-none d-none d-md-none d-xl-block d-lg-none" style={{ 'marginTop': '173px' }}>
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={sliderSantuario} alt="Basílica Menor Santuario Nacional Virgen de Coromoto"/>
                        </div>                       
                    </Carousel>
                </div>
                <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-none d-lg-block" style={{'marginTop': '173px'}}>
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={sliderLg} alt="Basílica Menor Santuario Nacional Virgen de Coromoto"/>
                        </div>                                                                                                       
                    </Carousel>
                </div>
                <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-none d-none" style={{'marginTop': '80px'}}>
                <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={sliderMd} alt="Basílica Menor Santuario Nacional Virgen de Coromoto"/>
                        </div>                        
                    </Carousel>
                </div>
                <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-block d-block" style={{'marginTop': '80px'}}>
                <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={history1} alt="Basílica Menor Santuario Nacional Virgen de Coromoto"/>
                        </div>
                        <div>
                            <img src={history2} alt="Basílica Menor Santuario Nacional Virgen de Coromoto"/>
                        </div>
                    </Carousel>
                </div>                
            </div>
        );
    }
    
}