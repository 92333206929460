/**
 * TimtableSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 02/01/2025
 */

import React, { Component } from 'react';
import Header from './header';
import PrayersSanctuarySliders from './prayersSanctuarySliders';
import Footer from './footer';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import CoreServices from '../services/CoreService';


export default class VideosSanctuary extends Component {

    videosList = [];
    service;

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        if (sessionStorage.getItem('language') !== null) {
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };
        }
        else {
            this.state = { lang: language, eventChangeLanguage: onLanguageChange };
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);
        this.service = new CoreServices();
        this.videosList = this.service.getVideosList();
        this.state = { videos: this.videosList };
    }

    componentDidMount() {
        if (sessionStorage.getItem('language') !== null) {
            if (this.state.eventChangeLanguage !== null && typeof this.state.eventChangeLanguage !== "undefined") {
                this.setState({ lang: sessionStorage.getItem('language') });
                sessionStorage.setItem('language', sessionStorage.getItem('language'));
                this.state.eventChangeLanguage(sessionStorage.getItem('language'));
            }
        }
    }


    filterVideos = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const searchKey = formData.get('searchKey');
        this.filterSearch(searchKey);
    }

    handleInputChange = (event) => {
        const searchTerm = event.target.value;
        this.filterSearch(searchTerm);
    }

    filterSearch(searchKey) {
        if (searchKey && (typeof searchKey === "string" && searchKey.trim().length !== 0)) {
            this.videosList = this.videosList.filter(video => video.title.toLowerCase().includes(searchKey.toLowerCase()));
            this.setState({
                videos: this.videosList
            });
        }
        else {
            this.videosList = [];
            this.videosList = this.service.getVideosList();
            this.setState({
                videos: this.videosList
            });
        }
    }


    refreshLanguage(value) {
        if (value !== null && value !== '') {
            this.setState({ lang: value });
        }
    }

    render() {
        return (<div>
            <Helmet>
                <title>Videos Virgen de Coromoto Patrona de Venezuela</title>
                <meta name="description" content="Videos Virgen de Coromoto Patrona de Venezuela" />
                <meta name="keywords" content="videos, basilica, santuario, virgen, coromoto" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <PrayersSanctuarySliders />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row" >
                    <div className="col-12" style={{ "color": "#927f26", "fontWeight": "bold" }} align="center"><h2>Videos Basílica Menor Santuario Nacional de Nuestra Señora de Coromoto</h2></div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>


                <form className="d-flex mb-3" role="search" onSubmit={this.filterVideos}>
                    <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" name="searchKey" onChange={this.handleInputChange} />
                    <button className="btn btn-outline-success" type="submit">Filtrar</button>
                </form>


                {this.videosList.map(video => (
                    <div className="card mb-3 d-sm-none d-none d-lg-block d-md-block d-xl-block" key={video.key}>
                        <div className="row g-0">
                            <div className="col-md-6">
                                <Link to={{ pathname: '/video-detail.html', search: "?vi=" + video.getIdVideoYouTube() }}>
                                    <img src={require("../img/videos/500_280/" + video.getImageYouTube())} alt='{video.getTitle()}' className="img-fluid rounded" />
                                </Link>
                            </div>
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h5 className="card-title"><Link className="text-sanctuary" to={{ pathname: '/video-detail.html', search: "?vi=" + video.getIdVideoYouTube() }}>{video.getTitle()}</Link></h5>
                                    <p className="card-text">{video.getDescription()}</p>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                ))}




                {this.videosList.map(video => (
                    <div className="card d-xl-none d-lg-none d-md-none d-block d-sm-block" key={video.key}>
                        <Link to={{ pathname: '/video-detail.html', search: "?vi=" + video.getIdVideoYouTube() }}>
                            <img src={require("../img/videos/500_280/" + video.getImageYouTube())} alt='{video.getTitle()}' className="card-img-top img-fluid rounded" />
                        </Link>
                        <div class="card-body">
                            <h5 className="card-title"><Link className="text-sanctuary" to={{ pathname: '/video-detail.html', search: "?vi=" + video.getIdVideoYouTube() }}>{video.getTitle()}</Link></h5>
                            <p className="card-text">{video.getDescription()}</p>                            
                        </div>
                    </div>
                ))}



            </div>
            <Footer />
        </div>);
    }

}