/**
 * DonationsSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 28/03/2020
 */

import React, { Component } from 'react';
import Header from './header';
import Footer from './footer';
import logo from '../img/gallery-holy-relic/992/SantaReliquiaCuadro.jpg';
import logo2 from '../img/gallery-holy-relic/992/SantaReliquiaCuadro-small.jpg';
import {Helmet} from "react-helmet";
import FindingsHolyRelicSlider from "./FindingsHolyRelicSlider";
import pendon1 from '../img/gallery-holy-relic/pendon-virgen-coromoto-1-small.jpg';
import pendon2 from '../img/gallery-holy-relic/pendon-virgen-coromoto-2-small.jpg';
import pendon3 from '../img/gallery-holy-relic/pendon-virgen-coromoto-3-small.jpg';
import pendon4 from '../img/gallery-holy-relic/pendon-virgen-coromoto-4-small.jpg';
import { Trans } from '@lingui/macro';

export default class FindingsHolyRelic extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : (this.props.location.pathname.replace("/", "").includes("findings-holy-relic-our-lady-of-coromoto.html") ? "en" : "es");
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;      
        if (sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);    
        this.invokeChangeLanguage = this.invokeChangeLanguage.bind(this);
    }


    componentDidMount() {
        if ((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') || (this.state.lang!==null && this.state.lang==='en')) {            
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
               this.invokeChangeLanguage((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') ? sessionStorage.getItem('language') : this.state.lang);  
            }
        }        
    }

    invokeChangeLanguage(language) {
        if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {                         
            this.setState({ lang: language });         
            sessionStorage.setItem('language', language);        
            this.state.eventChangeLanguage(language);            
        }
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        let helmetComp;
        if (this.state.lang === 'es') {
            //seo 
            helmetComp = <Helmet>
            <title>Hallazgos Santa Reliquia Virgen de Coromoto</title>
            <meta name="description" content="Hallazgos Santa Reliquia Virgen de Coromoto" />
            <meta name="keywords" content="patrona, reliquia, santa reliquia, coromoto" />
        </Helmet>;
        }
        else {
            //seo 
            helmetComp = <Helmet>
                <title>Findings Holy Relic Our Lady of Coromoto</title>
                <meta name="description" content="Findings Holy Relic Our Lady of Coromoto" />
                <meta name="keywords" content="Coromoto, holy, relic, findings" />
            </Helmet>;
        }

        return (<div>
            {helmetComp}
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <FindingsHolyRelicSlider />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><Trans>ConservationTreatmentLb</Trans>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-md-12 d-none d-sm-none d-md-block d-xl-block d-lg-block" align="center">
                                        <img src={logo} alt="Santa Reliquia Virgen de Coromoto"/>
                                    </div>
                                    <div className="col-md-12 d-md-none d-xl-none d-lg-none d-block d-sm-block" align="center">
                                        <img src={logo2} className="img-thumbnail" alt="Santa Reliquia Virgen de Coromoto"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                        <p style={{ "text-align": "justify" }}><span style={{"font-size":"18px"}}><strong><Trans>ConservationTreatmentHd</Trans></strong></span></p>

                                            <p style={{ "text-align": "justify" }}>&nbsp;</p>

                                            <p style={{ "text-align": "justify" }}><Trans>ConservationTreatmentP1</Trans></p>
                                            <p style={{ "text-align": "justify" }}><Trans>ConservationTreatmentP2</Trans></p>
                                            <p style={{ "text-align": "justify" }}><Trans>ConservationTreatmentP3</Trans></p>
                                            <p style={{ "text-align": "justify" }}><Trans>ConservationTreatmentP4</Trans></p>
                                            <p style={{ "text-align": "justify" }}><Trans>ConservationTreatmentP5</Trans></p>
                                            <p style={{ "text-align": "justify" }}><Trans>ConservationTreatmentP6</Trans></p>
                                            <p style={{ "text-align": "justify" }}><Trans>ConservationTreatmentP7</Trans></p>
                                            <p style={{ "text-align": "justify" }}><Trans>ConservationTreatmentP8</Trans></p>

                                        </article>
                                    </div>
                                </div>

                                <div className="row">                                    
                                        <div className="col-md-12 d-none d-sm-none d-md-none d-xl-block d-lg-block" align="center">
                                             <iframe width="650" height="450" src="https://www.youtube.com/embed/8fRyWqrOidA?autoplay=0" allowfullscreen="allowfullscreen" title="Virgen de Coromoto"></iframe>
                                        </div>                                        
                                </div>

                                <div className="col-md-12 d-none d-sm-none d-md-none d-xl-block d-lg-block">
                                    <div class="col-12">&nbsp;</div>
                                </div>

                                <div className="row">                                    
                                        <div className="col-md-6 d-none d-sm-none d-md-none d-xl-block d-lg-block" align="center">
                                            <img src={pendon1} className="img-thumbnail" alt="Hallazgos Santa Reliquia Virgen de Coromoto"/>
                                        </div>
                                        <div className="col-md-6 d-none d-sm-none d-md-none d-xl-block d-lg-block" align="center">
                                            <img src={pendon2} className="img-thumbnail" alt="Hallazgos Santa Reliquia Virgen de Coromoto"/>
                                        </div>                                                                           
                                </div>

                                <div className="row">                                    
                                        <div className="col-md-6 d-none d-sm-none d-md-none d-xl-block d-lg-block" align="center">
                                            <img src={pendon3} className="img-thumbnail" alt="Hallazgos Santa Reliquia Virgen de Coromoto"/>
                                        </div>
                                        <div className="col-md-6 d-none d-sm-none d-md-none d-xl-block d-lg-block" align="center">
                                            <img src={pendon4} className="img-thumbnail" alt="Hallazgos Santa Reliquia Virgen de Coromoto"/>
                                        </div>                                                                           
                                </div>

                             

                                <div className="row">                                    
                                        <div className="col-md-12 d-none d-sm-none d-md-block d-xl-none d-lg-none" align="center">
                                            <iframe width="600" height="487" src="https://www.youtube.com/embed/8fRyWqrOidA?autoplay=0" allowfullscreen="allowfullscreen" title="Virgen de Coromoto"></iframe>
                                        </div>                                                                                                              
                                </div>

                                <div className="row">
                                    <div class="col-md-12 d-none d-sm-none d-md-block d-xl-none d-lg-none">&nbsp;</div>
                                </div>

                           

                                <div className="row">                                    
                                        <div className="col-md-12 d-none d-sm-none d-md-block d-xl-none d-lg-none" align="center">
                                            <img src={pendon1} className="img-thumbnail" alt="Hallazgos Santa Reliquia Virgen de Coromoto"/>
                                        </div>                                                                                                              
                                </div>

                                <div className="row">                                    
                                        <div className="col-md-12 d-none d-sm-none d-md-block d-xl-none d-lg-none" align="center">
                                            <img src={pendon2} className="img-thumbnail" alt="Hallazgos Santa Reliquia Virgen de Coromoto"/>
                                        </div>                                                                                                              
                                </div>

                                <div className="row">                                    
                                        <div className="col-md-12 d-none d-sm-none d-md-block d-xl-none d-lg-none" align="center">
                                            <img src={pendon3} className="img-thumbnail" alt="Hallazgos Santa Reliquia Virgen de Coromoto"/>
                                        </div>                                                                                                              
                                </div>

                             
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                   
                                <div className="row">
                                    <div class="col-md-12 d-md-none d-xl-none d-lg-none d-block d-sm-block" align="center">
                                        <iframe className="img-thumbnail2" width="400" height="325" src="https://www.youtube.com/embed/8fRyWqrOidA?autoplay=0" allowfullscreen="allowfullscreen" title="Virgen de Coromoto"></iframe>
                                    </div>
                                </div>


                                <div className="row">
                                    <div class="col-md-12 d-md-none d-xl-none d-lg-none d-block d-sm-block" align="center">
                                        <img src={pendon1} className="img-thumbnail" alt="Hallazgos Santa Reliquia Virgen de Coromoto"/>
                                    </div>
                                </div>

                                <div className="row">
                                    <div class="col-md-12 d-md-none d-xl-none d-lg-none d-block d-sm-block" align="center">
                                        <img src={pendon2} className="img-thumbnail" alt="Hallazgos Santa Reliquia Virgen de Coromoto"/>
                                    </div>
                                </div>

                                <div className="row">
                                    <div class="col-md-12 d-md-none d-xl-none d-lg-none d-block d-sm-block" align="center">
                                        <img src={pendon3} className="img-thumbnail" alt="Hallazgos Santa Reliquia Virgen de Coromoto"/>
                                    </div>                                   
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                


            </div>
            <Footer />
        </div>);
    }

}