
import React, { Component } from 'react';
import Header from './header';
import Footer from './footer';
import { Helmet } from "react-helmet";
import CoreServices from '../services/CoreService';

export default class VideosSanctuaryDetail extends Component {

    videosList = [];
    videoId;
    videoDetail;

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        if (sessionStorage.getItem('language') !== null) {
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };
        }
        else {
            this.state = { lang: language, eventChangeLanguage: onLanguageChange };
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);
        let service = new CoreServices();
        this.videosList = service.getVideosList();
        const videoId = new URLSearchParams(props.location.search).get('vi');
        this.videoDetail = this.getVideo(videoId);
    }

    getVideo(videoId) {
        if (videoId && (typeof videoId === "string" && videoId.trim().length !== 0)) {
            let videoFilterList = this.videosList.filter(video => video.getIdVideoYouTube() === videoId);
            if (videoFilterList.length === 1) {
                return videoFilterList[0];
            }
        }
    }

    componentDidMount() {
        if (sessionStorage.getItem('language') !== null) {
            if (this.state.eventChangeLanguage !== null && typeof this.state.eventChangeLanguage !== "undefined") {
                this.setState({ lang: sessionStorage.getItem('language') });
                sessionStorage.setItem('language', sessionStorage.getItem('language'));
                this.state.eventChangeLanguage(sessionStorage.getItem('language'));
            }
        }
    }

    refreshLanguage(value) {
        if (value !== null && value !== '') {
            this.setState({ lang: value });
        }
    }

    render() {
        if (this.videoDetail) {
            return (
                <div>
                    <Helmet>
                        <title>{this.videoDetail.getTitle()}</title>
                        <meta name="description" content="{this.videoDetail.getDescription()}" />
                        <meta name="keywords" content="video, virgen, coromoto, patrona, venezuela, reliquia" />
                    </Helmet>
                    <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{ 'marginTop': '173px' }}>&nbsp;</div>
                            <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{ 'marginTop': '80px' }}>&nbsp;</div>
                        </div>
                        <div className="row">
                            <div className="col-12">&nbsp;</div>
                        </div>

                        <div className="row" >
                            <div className="col-12" style={{ "color": "#927f26", "fontWeight": "bold" }} align="center"><h2>Videos Basílica Menor Santuario Nacional de Nuestra Señora de Coromoto</h2></div>
                        </div>

                        <div className="row">
                            <div className="col-12">&nbsp;</div>
                        </div>
                        <div className="row">
                            <div class="card">
                                <iframe width="100%" height="470" src={this.videoDetail.getUrlYouTube()}
                                    title="{this.videoDetail.getTitle()}" frameborder="0" allow="accelerometer;  autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                                    referrerPolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                <div class="card-body">
                                    <h5 className="card-title">{this.videoDetail.getTitle()}</h5>
                                    <p className="card-text">{this.videoDetail.getDescription()}.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">&nbsp;</div>
                        <div className="row">&nbsp;</div>
                        <div className="row">&nbsp;</div>
                        <div className="row"><label className="text-sanctuary">Otros Enlaces de Interés:</label></div>
                        <div className="row"><a href='historia-virgen-coromoto.html'>Historia de la Aparición de la Santísima Virgen María de Coromoto</a></div>
                        <div className="row"><a href='hallazgos-santa-reliquia-virgen-coromoto.html'>Hallazgos de la Santa Reliquia de la Santísima Virgen María de Coromoto</a></div>
                        <div className="row"><a href='carta-pastoral-santa-reliquia-virgen-coromoto.html'>Carta Pastoral MONS. JOSÉ SOTERO VALERO RUZ, V OBISPO DE GUANARE</a></div>
                        <div className="row">&nbsp;</div>
                        <div className="row">&nbsp;</div>
                        <div className="row">
                            <div class="col-12" align="center"><a href="videos-santuario-nacional-virgen-coromoto.html">Regresar</a></div>
                        </div>
                        <div className="row">&nbsp;</div>
                        <div className="row">&nbsp;</div>
                    </div>
                    <Footer />
                </div>
            );
        }
        else {
            return (
                <div>
                    <Helmet>
                        <title>Videos Basílica Menor Santuario Nacional de Nuestra Se&ntilde;ora de Coromoto</title>
                        <meta name="description" content="Santa Reliquia Virgen de Coromoto" />
                        <meta name="keywords" content="reliquia, santa reliquia, virgen, coromoto" />
                    </Helmet>
                    <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{ 'marginTop': '173px' }}>&nbsp;</div>
                            <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{ 'marginTop': '80px' }}>&nbsp;</div>
                        </div>
                        <div className="row">Video No Encontrado</div>
                    </div>
                    <Footer />
                </div>
            );
        }
    }
}